/* src/components/VideoPlayer/VideoPlayer.css */
.video-player-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  height: 55%;
  background-color: #FFF8F8;
  border-radius: 12px;
  padding: 20px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
  margin-bottom: 20px;
}

.back-icon {
  width: 39px;
  height: 39px;
  cursor: pointer;
}

.file-path {
  font-family: Inter, sans-serif;
  font-size: 21px;
  font-weight: 500;
  text-align: center;
}

.video-player {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  border-radius: 12px;
  object-fit: contain;
  background-color: black;
  margin-bottom: 20px;
}

.info-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.info-box {
  display: flex;
  justify-content: space-between;
  width: 294px;
  height: 37px;
  border-radius: 5px;
  border: 1px solid #CECECE;
  background: #FFF;
  padding: 0 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.info-text-right {
  color: #000;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 21px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
}

.info-text-left {
  color: #18005B;
  text-align: right;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 21px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
}

.actions-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 300px;
  margin-bottom: 10px;
}

.dropdown {
  width: 120px;
  margin-right: 20px;
  background-color: #fff;
}

.enhance-button {
  width: 150px;
  height: 36px;
  border-radius: 4px;
  background: linear-gradient(270deg, #720000 0%, #D80000 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #FFF;
  -webkit-text-fill-color: #FFF;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  line-height: 14.063px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.enhance-button:hover {
  background: linear-gradient(270deg, #D80000 0%, #720000 100%);
}

.hardware-encoding-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
}

.hardware-encoding-text {
  color: #585858;
  text-align: center;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 10.873px;
  font-weight: 500;
  line-height: 8.994px;
  margin-right: 10px;
}


.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  color: #000;
  font-family: Roboto, sans-serif;
  font-size: 17.25px;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.574px;
  text-align: center;
}
