/* App.css */
body {
  margin: 0;
  font-family: Inter, sans-serif;
  background-color: #600;
}

.main-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 90%;
  background: #FFF8F8;
  border-radius: 40px 40px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
}

.heading {
  color: #FFF;
  font-size: 34px;
  font-weight: 700;
  text-align: center;
  font-feature-settings: 'liga' 0, 'clig' 0;
  letter-spacing: 0.251px;
  margin-top: 40px;
}

.video-upload {
  border: dashed 2px #CCC;
  padding: 20px;
  width: 80%;
  margin-top: 20px;
  text-align: center;
}

/* App.css */
.custom-uploader {
  display: flex;
  max-width: 1000px;
  width: 50vw; /* Adjusted from fixed px to relative vw for responsiveness */
  height: 15vh; /* Adjusted from fixed px to relative vh for responsiveness */
  flex-direction: column;
  align-items: center; /* Changed from flex-start to center for better alignment */
  flex-shrink: 0;
}
