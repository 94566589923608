/* src/components/EnhancedResult/EnhancedResult.css */
.enhanced-result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 90%;
  max-width: 1000px;
  height: auto;
  padding: 20px;
  box-sizing: border-box;
}

.video-comparison-container {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 20px;
}

.caption {
  position: absolute;
  top: 5px;
  left: 5px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  border-radius: 4px;
}

.video-container {
  width: 45%;
  height: auto;
  position: relative;
}

video {
  width: 100%;
  height: auto;
  object-fit: contain;
  background-color: black;
  border-radius: 12px;
}

.info-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.info-box {
  display: flex;
  justify-content: space-between;
  width: 294px;
  height: 37px;
  border-radius: 5px;
  border: 1px solid #CECECE;
  background: #FFF;
  padding: 0 10px;
  box-sizing: border-box;
}

.info-text-right, .info-text-left {
  color: #000;
  font-family: Inter;
  font-size: 21px;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
}
