.custom-uploader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center vertically */
    width: 100%;
    height: 100vh; /* Make container take full viewport height */
    padding: 20px; /* Add space around the box */
  }
  
  .custom-uploader {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 11.536px;
    border-radius: 8px;
    border: 2.884px dashed rgba(0, 0, 0, 0.12);
    background: #FFF;
    box-shadow: 0px 5.768px 5.768px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    max-width: 600px; /* Limit the max-width of the uploader box */
    height: auto;
    cursor: pointer;
    padding: 40px 20px; /* Add padding inside the uploader box */
  }
  
  
  .avatar {
    /* border-radius: 104.2px; */
    display: inline-flex;
    justify-content: center;
    position: relative;
    color: #2196F3;
  }
  
  .upload-file-filled {
    width: 180px;
    height: 180px;
  }
  
  .title {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
  
  .text-wrapper {
    color: #2196F3;
    font-size: 27.4px;
    font-weight: 600;
    letter-spacing: 0.22px;
    line-height: 40px;
    white-space: nowrap;
  }
  
  .underline {
    width: 100%;
    height: 1.44px;
    background-color: #2196F3;
    opacity: 0.4;
  }
  
  .or-drag-and-drop {
    color: #333;
    font-size: 27.4px;
    font-weight: 500;
    letter-spacing: 0.22px;
    line-height: 40px;
    white-space: nowrap;
  }
  
  .file-types {
    color: rgba(0, 0, 0, 0.6);
    font-size: 23.1px;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
  }
  
  .file-name {
    margin-top: 10px;
    font-size: 18px;
    color: #555;
  }
  